import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  user:{},
  isUserLoggedIn: null,
  isLoading: true,

};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    logout: (state) => {
      state.user = {};
      state.isUserLoggedIn = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(me.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(me.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isUserLoggedIn = true;
      state.isLoading = false;
    })
    builder.addCase(me.rejected, (state) => {
      state.isLoading = false;
      state.isUserLoggedIn = null;
    })
  }
});

export const me = createAsyncThunk('auth/me', async (_, thunkAPI) => {
  try {
    const URL = 'https://genai-api.nawatech.co/api/users/get-role';
    const response = await axios.get(URL, {
      headers: {
        'x-api-key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      },
      withCredentials: true,
    })

    if(response.status === 200){
      return thunkAPI.fulfillWithValue(response.data.data)
    }else{
      throw new Error(response.statusText)
    }
  }catch (error) {
    return thunkAPI.rejectWithValue("Failed to fetch role")
  }
})

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
