import sinarmasLogo from "./assets/sinarmas.png"

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            <img src={sinarmasLogo} style={{height:"60px"}}/>
            <div>Page not found | 404</div> 
        </div>
    )
}

export default NotFoundPage