import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toasts: []
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast: {
            reducer(state, action) {
                state.toasts.push(action.payload);
            },
            prepare(props) {
                return {
                    payload: {
                        id: Math.random().toString(16).slice(2),
                        ...props
                    }
                }
            }
        },
        removeToast: (state, action) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        }
    }
});

export const { addToast, removeToast } = toastSlice.actions;

export default toastSlice.reducer;