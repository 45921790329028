import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { me } from "../redux/slices/authSlice";
import LoadingPage from "../LoadingPage";

const { Outlet, Navigate, useSearchParams } = require("react-router-dom");


const PrivateRoutes = () => {
    const dispatch = useDispatch();
    const {isLoading, isUserLoggedIn} = useSelector((state) => state.auth)


    useEffect(() => {
        if(isUserLoggedIn === null){
            dispatch(me())
        }
    }, [])

    if(isLoading) return <LoadingPage />

    return (
        isUserLoggedIn ? <Outlet /> : <Navigate to={'/login'} />
    )
}

export default PrivateRoutes;