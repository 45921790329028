import React from 'react';
import { useDispatch } from 'react-redux';
import { addToast } from '../redux/slices/toastReducer'; // Adjust the path as necessary
import ToastsRedux from '../components/ToastsRedux';

const AlertTestReduxPage = () => {
    const dispatch = useDispatch();

    const handleSuccessAlert = () => {
        dispatch(addToast({
            type: 'success',
            title: 'Success',
            description: null
        }));
    };

    const handleErrorAlert = () => {
        dispatch(addToast({
            type: 'error',
            title: 'Error',
            description: 'This is an error alert'
        }));
    }

    const handleWarningAlert = () => {
        dispatch(addToast({
            type: 'warning',
            title: 'Warning',
            description: 'This is a warning alert'
        }));
    }

    const handleInfoAlert = () => {
        dispatch(addToast({
            type: 'info',
            title: 'Info',
            description: 'This is an info alert'
        }));
    }

    return (
        <div>
            <ToastsRedux />
            <h1>Alert Test From React Context</h1>
            <button onClick={handleSuccessAlert}>Success Alert</button>
            <button onClick={handleErrorAlert}>Error Alert</button>
            <button onClick={handleWarningAlert}>Warning Alert</button>
            <button onClick={handleInfoAlert}>Info Alert</button>
        </div>
    );
};

export default AlertTestReduxPage;