import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDDesktop from './MVDDesktop/MVDDesktop';
import "./App.css";
import PrivateRoutes from './auth/PrivateRoute';
import NotFoundPage from './NotFoundPage';
import DashboardValidator from './auth/DashboardValidator';
import AlertTestReduxPage from './AlertTest/AlertTestReduxPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={'/home'} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Homepage />} />
          <Route element={<DashboardValidator />}>
            <Route path="/dashboard/:dashboard_id" element={<MVDDesktop />} />
          </Route>
        </Route>
        <Route path="/alert-test" element={<AlertTestReduxPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;